export default {
buildTime: "2024-04-30T16:33:15Z",
commit: "2b86c4774670955a1f0827affab3e808131c545b",
npmInfo:
{
  mashlib: '1.8.11',
  npm: '8.19.4',
  node: '18.19.0',
  acorn: '8.10.0',
  ada: '2.7.2',
  ares: '1.20.1',
  base64: '0.5.0',
  brotli: '1.0.9',
  cjs_module_lexer: '1.2.2',
  cldr: '43.1',
  icu: '73.2',
  llhttp: '6.0.11',
  modules: '108',
  napi: '9',
  nghttp2: '1.57.0',
  nghttp3: '0.7.0',
  ngtcp2: '0.8.1',
  openssl: '3.0.12+quic',
  simdutf: '3.2.18',
  tz: '2023c',
  undici: '5.26.4',
  unicode: '15.0',
  uv: '1.44.2',
  uvwasi: '0.0.19',
  v8: '10.2.154.26-node.28',
  zlib: '1.2.13.1-motley'
}
};
